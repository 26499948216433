@media (min-width:320px) {
.main-body{
    overflow: auto !important;
    height: 100% !important;
}
    /* smartphones, iPhone, portrait 480x320 phones */
    .navbar-mobile {
        margin-top: .4rem !important;
    }
    .custom-textarea{
        width: 300px !important;
    }
    .checkboxCol {
        width: 100% !important;
    }

    /* start here */
    .navbar-top-height {
        height: 100px !important;
        padding-top: 70px !important;
        padding-bottom: 50px !important;

    }

    .form-label {
        font-size: 14px !important;
    }
    .name-label{
        font-size: 15px !important;
    }

    .avatar-menu-res {
        margin-bottom: -5px !important;
    }

    .dashboard-dropdown-log {
        position: relative;
        top: 40px !important;
        right: -300px;

    }

    .navbarTop-onTop {
        position: absolute;
        top: 100px;
    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .menu-header-bg {
        position: absolute;
        left: 2px;
        width: 300px !important;
        top: -28px;
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .toggle-icon-wrapper {
        position: absolute;
        left: 2px;
        top: -75px !important;
    }

    .navbar-brand {
        position: absolute;
        top: -70px !important;
        left: 40px;
    }

    .avatar-menu-res {
        position: relative;
        top: -52px;
    }

    .dashboard-dropdown-log {
        position: relative;
        top: -30px !important;
    }

    .navbar-top-button {
        position: relative;
        left: 5px;
        top: -50px;
    }

    .navbar-glass {
        background-color: #edf2f9 !important;
    }

}

@media (min-width:481px) {
    .custom-textarea{
        width: 400px !important;
    }
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    .navbar-mobile {
        margin-top: .4rem !important;
    }

    .checkboxCol {
        width: 100% !important;
    }

    .dashboard-dropdown-log {
        position: absolute;
        top: 75px !important;
        right: 5px;
    }

    .navbarTop-onTop {
        position: absolute;
        top: 100px;
    }
    .name-label{
        font-size: 15px !important;
    }

}

@media (min-width: 576px) {
    .custom-textarea{
        width: 250px !important;
    }
    .content {
        padding-bottom: 2.2875rem !important;
    }

    .navbar-mobile {
        margin-top: .4rem !important;
    }

    .dashboard-dropdown-log {
        position: absolute;
        top: 75px !important;
        right: 5px;
    }

    .navbarTop-onTop {
        position: absolute;
        top: 100px;
    }
    .name-label{
        font-size: 15px !important;
    }

}

@media (min-width:641px) {
    .custom-textarea{
        width: 250px !important;
    }
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
    .navbar-mobile {
        margin-top: .4rem !important;
    }

    .dashboard-dropdown-log {
        position: absolute;
        top: 75px !important;
        right: 5px;
    }

    .navbarTop-onTop {
        position: absolute;
        top: 100px;
    }

}

@media (min-width: 768px) {
    .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
        background-color: #1862c6 !important;
        margin-left: -5px !important;
    }
    
    .fc .fc-daygrid-day-frame {
        border: 1px solid;
        text-align: center !important;
        margin-left: -5px !important;
        padding: 0px !important;
    }
    .container {
        /* max-width: 1024px; */
    }

    .navbar-mobile {
        margin-top: .4rem !important;
    }

    .checkboxCol {
        width: 100% !important;
    }

    .dashboard-dropdown-log {
        position: absolute;
        top: 75px !important;
        right: 5px;
    }

    .navbarTop-onTop {
        position: absolute;
        top: 100px;
    }

    .form-label {
        font-size: 13px !important;
    }
}

@media (min-width:961px) {
    .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
        background-color: #1862c6 !important;
        padding: 5px !important;
        margin-left: 2px !important;
    }
    .fc .fc-daygrid-day-frame {
        margin-left: 4px !important;
    }
   
    .custom-textarea{
        width: 300px !important;
    }
    .navbar-mobile {
        margin-top: 0px !important;
    }

    .checkboxCol {
        width: 100% !important;
    }

    /* start here */

    .form-label {
        font-size: 13px !important;
    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .menu-header-bg {
        position: absolute;
        left: 2px;
        width: 300px !important;
        top: -28px;
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .toggle-icon-wrapper {
        position: absolute;
        left: 2px;
        top: -75px !important;
    }

    .navbar-brand {
        position: absolute;
        top: -70px !important;
        left: 40px;
    }

    .avatar-menu-res {
        position: relative;
        top: -52px;
    }

    .navbar-top-button {

        position: relative;
        left: 5px;
        top: -50px;
    }

    .navbar-glass {
        background-color: #edf2f9 !important;
    }

    .avatar-menu-res {
        margin-bottom: -5px !important;
    }
    .custom-textarea {
        width: 535px  !important; 
      }

}

@media (min-width:1025px) {
    .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
        background-color: #1862c6 !important;
        padding: 5px !important;
        margin-left: 2px !important;
    }
    .custom-textarea {
        width: 535px  !important; 
      }
    .micro-form .form-control,
    .micro-form input::placeholder,
    .micro-form .form-select,
    .micro-form .error-message {
        font-size: 11px !important;
    }

    .checkboxCol {
        width: 50% !important;
    }

    .checkboxText {
        font-size: 11px !important;
    }

    .sendWhatsAppCol {
        width: 100% !important;
    }

    /* .navbar-vertical.navbar-expand-xl+.content {
        margin-left: 12.825rem !important;
    } */
    .navbar-mobile {
        margin-top: 0px !important;
    }

    .dashboard-dropdown-log {
        position: absolute;
        top: 85px !important;
        right: 5px;

    }

    .form-label {
        font-size: 12px !important;
    }

    .avatar-menu-res {
        margin-bottom: -100px !important;

    }

    .navbarTop-onTop {
        position: absolute;
        top: 33px;
    }


    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl+.content {
        margin-left: 3.1rem !important;
    }

    .footer-credits {
        margin-left: 230px !important;
    }

    .tab-page-tab {
        max-height: 465px;
        overflow-y: scroll;
    }

    .list-card {
        min-height: 560px;
        max-height: 560px;
        overflow-y: scroll;
    }

    .max-five-rows {
        max-height: 291px;
        overflow-y: scroll;
    }

    .nav-tabs .nav-link,
    .form-label,
    .form-select,
    .form-control,
    input::placeholder,
    .table-striped {
        font-size: 11px !important;
    }

    .btn {
        font-size: 12px !important;
    }

    .h4,
    h4 {
        font-size: 1rem !important;
    }

    h5,
    h6 {
        font-size: .8rem !important;
    }

    tbody .form-control,
    tbody .form-select {
        min-width: 120px;
    }

    /* start here */
    .navbar-top-height {
        height: 36px;
        background-color: #2EA7FF !important;
        padding: 0 !important;
    }

    .navbar-dashboard {
        height: 35px !important;
        margin-top: -1px !important;
    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .menu-header-bg {
        position: relative;
        left: 20px;
        width: 100% !important;
        top: 0px;
    }

    .toggle-icon-wrapper {
        position: relative;
        left: 0px;
        top: 0px !important;
    }

    .navbar-brand {
        position: relative;
        top: 0px !important;
        left: 40px;
    }

    .avatar-menu-res {
        position: relative;
    }

    .navbar-top-button {
        position: relative;
        left: 5px;
        top: 0px;
    }

    .navbar-glass {
        background-color: #edf2f9 !important;
    }

    .name-label{
        font-size: 13px !important;
    }

    /* css end */
}

@media (min-width:1100px) {
    .main-body{
        overflow: hidden !important;
    }
    .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
        margin-left: 2px !important;
        padding: 3px !important;
    }
    .custom-textarea {
        width: 535px  !important; 
      }
    .avatar-menu-res {
        margin-bottom: -100px !important;
    }

    .navbar-dashboard {
        height: 35px !important;
    }

    .form-label {
        font-size: 13px !important;
    }
    .checkboxText {
        font-size: 11px !important;
    }
    .name-label{
        font-size: 13px !important;
    }
    
  
}  
@media (min-width: 1200px) {

    .client-dashboard-card,
    .next-case-list-card {
        /* border: 1px solid red !important; */
        /* border: 1px solid rgb(0, 60, 255) !important; */
        /* border: 1px solid red  !important ; */
        /* border: 1px solid rgb(81, 255, 0) !important; */
        /* height: calc(52vh - 4.3125rem) !important; */
        /* max-height: 400px !important;
        min-height: 300px !important; */
        width: 100%;
    }

            .client-card-body {
        padding: 0px !important;
            }
            .client-card-body tr td{
           padding: 1px!important;
           /* border: 1px solid red !important; */
            }
            .client-card-body .action-footer{
                margin-top: 5px !important;
                margin-bottom: 3px !important;
            } 
            
                .action-list-card {
              /* height: calc(25vh - 5.3125rem) !important;  */
              /* height: 300px !important;
              min-height: 250px; */
              /* border:1px solid red !important; */
                }

                .edit-icon{
                    height: 16px !important;
                }
                .next-case-list-card-body{
                    padding: 5px !important;
                }
            .action-list-card .search-text-column{
                /* margin-top: 4px !important; */
            }
            .client-dashboard-card .search-text-column{
                /* margin-top: -10px !important; */
            }
  
            .next-case-list-card-body tr td {
                /* padding:25px !important; */
            }

        .table-advance tr td {
            padding: 20px !important;
        }
    .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
        margin-left: 5px !important;
    }
    .custom-textarea {
        width: 535px  !important; 
      }
    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-collapse {
        .subMenuText {
            display: none;
        }
    }

    .checkboxCol {
        width: 50% !important;
    }

    .sendWhatsAppCol {
        width: 100% !important;
    }

    .container {
        /* max-width: 1600px !important; */
    }

    .max-five-rows {
        max-height: 295px;
        overflow-y: scroll;
    }

    .tab-page-button-table-card {
        min-height: 155px;
        overflow-y: scroll;
    }

    .navbar-dashboard {
        height: 34px !important;
    }

    .cp-table-card {
        min-height: 155px;
        overflow-y: scroll;
    }

    .mr-table-card {
        min-height: auto ;
        overflow-y: scroll;
    }

    .big-card-table {
        min-height: 320px;
        overflow-y: scroll;
    }

    .full-tab-card-body {
        min-height: 376px;
    }

    .full-tab-page-card-body {
        min-height: 470px;
    }

    .half-screen-card {
        min-height: 225px;
        overflow-y: scroll;
    }

    .big-card-body {
        min-height: 365px;
    }

    .navbar-mobile {
        margin-top: 0px !important;
    }

    /* start here */
    .dashboard-dropdown-log {
        position: absolute;
        top: 34px !important;
        right: 5px;

    }

    .navbarTop-onTop {
        position: absolute;
        top: 38px;
    }

    .navbar-top-height {
        height: 36px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .navbar-dashboard {
        border-top: 1px solid white;
        border-bottom: 1px solid white;
    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .menu-header-bg {
        position: relative;
        left: 0px;
        width: 100% !important;
        top: 0px;

    }

    .toggle-icon-wrapper {

        position: relative;
        left: 0px;
        top: 0px !important;
    }

    .navbar-brand {
        position: relative;
        top: 0px !important;
        left: 40px;
    }

    .avatar-menu-res {
        margin-bottom: -5px !important;
        position: relative;
        top: 0px;
    }

    .navbar-top-button {

        position: relative;
        left: 5px;
        top: 0px;
    }

    .navbar-glass {
        background-color: #2EA7FF !important;
    }
    .checkboxText {
        font-size: 11px !important;
    }
    .tab-page-tab {
        max-height: 475px;
        overflow-y: scroll;
    }

    /* css end */

}

@media (min-width:1280px) {
    
    .client-dashboard-card,
    .next-case-list-card {
        /* border: 1px solid rgb(21, 255, 0) !important; */
        /* border: 1px solid red  !important ; */
        /* border: 1px solid rgb(81, 255, 0) !important; */
        /* height: calc(52vh - 4.3125rem) !important; */
        /* max-height: 600px; */
        /* height: 500px !important; */
        width: 100%;
    }
    .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
        margin-left: 5px !important;
        padding: px !important;
        text-align: center !important;
    }
    .custom-textarea {
        width: 535px  !important; 
      }
    .container {
        max-width: 1600px !important;
    }

    .footer-credits {
        margin-left: 250px !important;
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl+.content {
        margin-left: 3.1rem !important;
    }

    .tab-page-tab {
        max-height: 475px;
        overflow-y: scroll;
    }

    .list-card {
        min-height: 400px;
        max-height: 450px;
        overflow-y: scroll;
    }

    .tab-page-table-card {
        max-height: 170px;
        overflow-y: scroll;
    }

    .tab-page-button-table-card {
        max-height: 162px;
        min-height: 162px;
        overflow-y: scroll;
    }

    .cp-table-card {
        max-height: 162px;
        min-height: 162px;
        overflow-y: scroll;
    }

    .cp-table-card-responsive {
        max-height: 162px;
        min-height: 300px !important;
        overflow-y: scroll;
    }

    .mr-table-card {
        min-height: 157px;
        max-height: auto !important;
        overflow-y: scroll;
    }

    .big-card-table {
        min-height: 320px;
        overflow-y: scroll;
    }

    h5 {
        font-size: 0.8rem !important;
    }

    .micro-form label {
        padding-left: 0px !important;
        padding-right: 0px !important;
        font-size: 12px !important;
    }

    .micro-form .form-control,
    .micro-form input::placeholder,
    .micro-form .form-select,
    .micro-form .error-message {
        font-size: 12px !important;
    }

    .max-five-rows {
        max-height: 290px;
        overflow-y: scroll;
    }

    .many-column-table .form-control {
        width: 105px !important;
    }

    .full-tab-card-body {
        min-height: 376px;
    }

    .farmer-card-row1 {
        min-height: 237px;
    }

    .farmer-card-row1 {
        min-height: 90% !important;
    }

    .farmer-card-row2 {
        min-height: 267px;
    }

    .full-tab-page-card-body {
        min-height: 462px;
    }

    .half-screen-card {
        min-height: 225px;
        overflow-y: scroll;
    }

    .big-card-body {
        min-height: 365px;
    }

    .select {
        width: 110px !important;
    }

    /* .enlargeText{
        width: 50px !important;
    } */
    .navbar-mobile {
        margin-top: 0px !important;
    }

    /* start here */
    .navbar-top-height {
        height: 36px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .menu-header-bg {
        position: relative;
        left: 0px;
        width: 100% !important;
        top: 0px;
    }

    .toggle-icon-wrapper {
        position: relative;
        left: 0px;
        top: 0px !important;
    }

    .navbar-brand {
        position: relative;
        top: 0px !important;
        left: 40px;
    }

    .avatar-menu-res {
        position: relative;
        top: 0px;
    }

    .navbar-top-button {
        position: relative;
        left: 5px;
        top: 0;
    }

    .navbar-glass {
        background-color: #2EA7FF !important;
    }

    .navbar-dashboard {
        height: 36px !important;
    }
    .checkboxText {
        font-size: 11px !important;
    }

    /* css end */
}
@media (min-width:1280px) and (max-height:559px) {
 
    .advocate-dealing-card{
        min-height: 390px !important;
        max-height:390px !important;
        /* border: 1px solid red !important; */
      }
    .litigation-detail-card-body {

        min-height: 300px !important;
        max-height: 300px !important;
        /* height: calc(100vh - 5.3125rem); */
    }
    .navbarMenus button{
        /* border: 1px solid   red !important; */
        height: 30px;
        padding: 5px;
    }
    .child-comman-card .card-body{
 
        /* border: 1px solid red !important; */
        min-height: 200px;
    }

.country-master-card{
    /* border: 1px solid red !important; */
    min-height: 50px;
    max-height: 30px;
    margin-bottom: 10px !important;
}

    .comman-card .card-body{
        text-wrap: nowrap !important;
        /* border: 1px solid red; */
        min-height: 390px;
        max-height: 390px;
    }
    /* .advocateProfile{
        border: 1px solid red;
        min-height: 100px;

        max-height: 100px;
        overflow-y: scroll;
    } */
    .tab-page-tab {
        
        min-height: 40vh; 
        max-height: 390px !important;
        overflow-y: scroll;
    }
        .list-card {
            min-height: 290px;
            max-height: 290px;
        }
        .footer {
            position: fixed !important; 
            bottom: 10px !important; 
            z-index: 1000;
          }
          .advocate-traction-card{
            min-height: 300px;
            max-height: 300px;
          }

          .advocate-dealing-card{
            min-height: 400px;
            max-height: 400px;
          }
/* .details-form{
    min-height: 400px;
    max-height: 400px;
} */
          /* .table-responsive{
            display: block;
            overflow-y: scroll;
            min-height: 450px;
            max-height: 450px;
          } */
          .full-tab-page-card-body{
            min-height: 600px;
            max-height: 600px;
          }
          
    
    .action-list-card-body table{
        overflow: scroll !important;
        display: block !important;
        height: 90px !important;
        width: 100% !important;
    }
        .next-case-list-card-body{
            padding-bottom: -5px !important;
            min-height: 100px !important;
            max-height: 170px !important;
        }
    
    .next-case-list-card-body table, .client-card-body table{
        overflow: scroll !important;
        display: block !important;
        min-height: 80px !important;
        max-height: 80px !important;
    }

    .client-dashboard-card{
        width: 101% !important;
        margin-left: -10px;
        padding: 5px;
    }
    .client-dashboard-card,
    .next-case-list-card{
        max-height: 100px !important;
        min-height: 155px !important;
    }
    .next-case-list-card{
        max-height: 100px !important;
        min-height: 155px !important;
    }
    .client-dashboard-card tr  td,
    .next-case-list-card tr td, .action-list-card tr td{
        padding: 0px !important;
        margin: 0px !important;
        padding-left: 5px !important;
    }

.next-case-list-card-body .action-button{
margin-top: -5px;
    }

    .action-list-card .search-text-column{
    
        margin-top: -10px !important;
    }
    .client-dashboard-card .search-text-column{
    
        margin-top: -7px !important;
    }
    .next-case-list-card .search-text-column{
        margin-top: -7px !important;
    }
    .dashboard-bolow-card{
        margin-top: 3px !important;
        margin-bottom:7px;
    }
    .calenderCard {
        max-height: 180px !important;
    }

.card-calendar .card-header{
    padding: 0px !important;
}
.fc .fc-daygrid-day-frame {
    padding: 0px !important;
    margin-top: 0px !important;
    margin-left: 1px !important;
    margin-bottom: 14px !important;
    text-align: center !important;
    width: 0px !important;
    height: 0px !important;
    font-size: 5px !important;
}
.fc-daygrid-day-number {
    font-weight: bold;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    font-size: 11px !important;
    margin-top: -5px;
    margin-left: -9px !important;
    font-weight: bold;
    padding: 0px !important ;
  }

  /* --------------------- */

  /* FullCalendar General Styles */
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: white !important;
    background-color: #2ea7ff !important;
    border-color: var(--bs-nav-tabs-link-active-border-color);
}

.fc {
    font-family: Arial, sans-serif;
    font-size: 14px;
    width: 100%; 
    max-width: 1200px; 
  }
  
  .fc-day:hover {
    background-color: #f5f5f5; 
    cursor: pointer;
  }

   .fc .fc-h-event {
    color: white !important;
    
}

.fc.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc.fc-direction-rtl .fc-daygrid-event.fc-event-end, .fc.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    width: 4px !important;
padding: 9px !important;
border-radius: 50% !important;
text-align: center !important;
}

.fc-daygrid-event-harness {
    margin-top: -31px  !important;
    margin-left: -10px !important;
    text-align: center !important;
    align-items: center !important;
}

.fc .fc-daygrid-event {
    font-size: 1px !important;
    background-color: var(--falcon-primary) !important;
    width: 30px !important;
    padding: -5px !important;
    z-index: 0 !important;
    text-align: center !important;
}

.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:hover {
    background-color: #05050598 !important;
    background: #000 !important;
    color: #f7f1f1 !important;
    padding: 5px !important; 
    
}
.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
    background-color: #23292d !important;
    color: #fff;
    padding: 1px;
}

.fc-daygrid-day .fc-event {
    margin-top: 1px !important  ;
    margin-left: 2px !important;
    margin-bottom: 5px !important;
    text-align: center;
    line-height: 30px; 
    width: 10px !important; 
    height: 10px !important;
    margin: 0 auto; 
  }
  
  .fc-daygrid-day .fc-event .fc-event-title {
    color: #fff; 
    font-size: 14px; 
    font-weight: bold;
  }
  
.has-event {
    color: white !important; 
    border: 2px solid blue; 
  }

  .tooltip-right {
    top: -5px;
    left: 105%;
  }
  
  .tooltip-left {
    top: -5px;
    right: 105%;
  }
  
  .tooltip-top {
    bottom: 105%;
    left: 0;
  }
  
  .tooltip-bottom {
    top: 105%;
    left: 0;
  }

  .calenderCard {
    padding-left: 0px !important;
      padding: 0px !important;
      margin: 0px !important;
  }

.fc-tooltip {
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 999;
  }
  
  .fc-tooltip .tooltip-arrow {
    position: absolute;
    top: 50%;
    left: -10px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 10px solid #333;
    transform: translateY(-50%);
  }
  
.fc-day-today{
    border: 2px solid rgba(var(--falcon-primary-rgb), .5) !important;
    
}
.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-frame {
    border: none !important;
}

.fc-daygrid-day-number {
    font-weight: bold;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
    font-size: 14px;
    margin-top: -6px;
    margin-left: 2px !important;
    font-weight: bold;
    padding: 0px !important ;
  }

  .windows.chrome .fc-scroller {
    overflow: hidden !important;
}

.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:hover {
    background-color: #1862c6 !important;
    padding: 0px !important;
}

.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
    background-color: #1862c6 !important;
    padding: 0px !important;
    width: 10px !important;
    height: 10px !important;
    margin-top: 0px !important;
    margin-right: -1px !important;
}

.fc .fc-scroller-harness {
    overflow: hidden !important;
}

.windows.chrome .fc-scroller {
    overflow: hidden !important;
}

.footer-mb{
    margin-top: -30px;
}

.footer-mb p {
    font-size: 11px !important;
}

.btn-falcon-default:hover , .button-arrow:hover{
    background-color: #2EA7FF !important;

    .button-arrow{
        color: white !important;
    }
}

.custom-table tbody tr:nth-child(odd) {
    background-color: #ffffff;
}

.custom-table tbody tr:nth-child(even) {
    background-color: #f9fafd;
}

.remove-card-bottom-p{
    padding-top: 7px !important;
    padding-bottom: 4px !important;
}

.fc-daygrid-day {
  width: 14.28%; 

}

.fc-daygrid-day {
    width: 5px !important; 
    height: 5px !important; 
    
  }

.table-advance  tr td{
    padding: 20px !important;
}

.table-title {
    color: red !important
}

.modelClientList{
    margin-left: -6px !important;
    width: 510px;
}
.table-advance td{
    color: #000 !important;
}

.client-list-header{
    color: #344050 !important;
}

.client-list-popup-card-heading
{
    margin-top: -6px !important;
}
.fc-day-today.has-event {
    .fc-daygrid-event {
        position: relative !important;
        top: 5px !important;
        left: 2px;
    }
}

.card-calendar{
    height: 270px !important;
    margin: 2px !important;
}
   
}

@media (min-width:1400px) {
    .client-card-body .action-footer, .next-case-list-card-body .action-footer{
        
        margin-top: -120px !important;
        margin-bottom: 0px !important;
        /* border: 1px solid red !important; */
    } 
    
    .client-dashboard-card,
    .next-case-list-card {
        width: 100%;
    }

        .table-advance tr td {
            padding: 5px !important;
        }
    .custom-textarea {
        width: 535px  !important; 
      }
    /* start here new css */

    .dashboard-dropdown-log {
        position: absolute;
        top: -6px !important;
        right: 5px;
    }

    .navbarTop-onTop {
        position: absolute;
        top: -1px !important;
    }

    .navbar-top-height {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        height: 36px !important;
    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .menu-header-bg {
        position: absolute;
        left: 2px;
        width: 300px !important;
        top: -28px;
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .toggle-icon-wrapper {

        position: absolute;
        left: 2px;
        top: -75px !important;
    }

    .navbar-brand {
        position: absolute;
        top: -70px !important;
        left: 40px;
    }

    .avatar-menu-res {
        position: relative;
        top: -52px;
    }

    .navbar-top-button {
        position: relative;
        left: 5px;
        top: -50px;
    }

    .navbar-glass {
        background-color: #2EA7FF !important;
    }

    /* end here new css */
    .checkboxCol {
        width: 50% !important;
    }

    .tab-page-tab {
        max-height: 690px !important;
        overflow-y: scroll;
    }

    .list-card {     
        min-height: 600px;
        max-height: 600px;
        overflow-y: scroll;
    }

    .tab-page-table-card {
        max-height: 215px;
        overflow-y: scroll;
    }

    .tab-page-button-table-card {
        min-height: 196px;
        max-height: 196px;
        overflow-y: scroll;
    }

    .cp-table-card {
        min-height: 233px;
        max-height: 233px;
        overflow-y: scroll;
    }

    .cp-table-card-responsive {
        min-height: 233px;
        max-height: 413px !important;
        overflow-y: scroll;
    }

    .mr-table-card {
        min-height: 226px;
        max-height: auto !important;
        overflow-y: scroll;
    }

    .big-card-table {
        min-height: 340px;
        overflow-y: scroll;
    }

    .max-five-rows {
        max-height: 294px;
        overflow-y: scroll;
    }

    .many-column-table .form-control {
        width: 135px !important;
    }

    .full-tab-card-body {
        min-height: 444px;
    }

    .full-tab-page-card-body {
        min-height: 525px;
    }

    .half-screen-card {
        min-height: 260px;
        overflow-y: scroll;
    }

    .big-card-body {
        min-height: 435px;
    }

    .select {
        width: 110px !important;
    }

    .enlargeText {
        width: 50px !important;
    }

    .navbar-mobile {
        margin-top: 0 !important;
    }
    .checkboxText {
        font-size: 13px !important;
    }

}

@media (min-width:1503) {
    .client-dashboard-card {
        /* border: 1px solid rgb(0, 162, 255) !important; */
        /* border: 1px solid rgb(0, 255, 42) !important; */
                height: calc(51.2vh - 4.3125rem) !important;
                margin-left: -10px !important;
                width: 101% !important;
                padding: 10px;
            }
        .table-advance tr td {
            padding: 14px !important;
        }
    .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
        padding: 3px !important;
        margin-left: 7px !important;
        text-align: center !important;
    }
    .custom-textarea {
        width: 535px  !important; 
      }
    .checkboxCol {
        width: 100% !important;
    }

    /* start here */
    .navbar-top-height {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        height: 36px !important;
    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .menu-header-bg {
        position: relative;
        left: 0px;
        width: 100% !important;
        top: 0px;
    }

    .toggle-icon-wrapper {
        position: relative;
        left: 0px;
        top: 0px !important;
    }

    .navbar-brand {
        position: relative;
        top: 0px !important;
        left: 40px;
    }

    .avatar-menu-res {
        position: relative;
        top: 0px;
    }

    .navbar-top-button {
        position: relative;
        left: 5px;
        top: 0px;
    }

    .navbar-glass {
        background-color: #2EA7FF !important;
    }

    .navbar-dashboard {
        height: 34px !important;
    }

    .form-label {
        font-size: 13px !important;
    }
    .checkboxText {
        font-size: 13px !important;
    }

    /* css end */
}

@media (min-width:1600px) {
    .footer {
        position: absolute; 
        bottom: 30px !important; 
        z-index: 1000;
      }
    .next-case-list-card-body table, .client-card-body table{
        overflow-y: scroll !important;
        display: block !important;
        height: 160px !important;
    }
    .client-dashboard-card,
    .next-case-list-card {

        max-height: 245px !important;
        height: 190px !important;
        /* width: 100%; */
    }

        .table-advance tr td {
            padding: 16px !important;
        }
    .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
        padding: 3px !important;
        margin-left: 7px !important;
        text-align: center !important;
    }
    .custom-textarea {
        width: 535px  !important; 
      }
    .checkboxCol {
        width: 50% !important;
    }
    .checkboxText {
        font-size: 13px !important;
    }

    .tab-page-tab {
        min-height: 87vh; 
        max-height: 900px !important;
        overflow-y: scroll;
    }

    .tab-page-button-table-card {
        min-height: 237px;
        max-height: 237px;
        overflow-y: scroll;
    }

    .cp-table-card {
        min-height: 310px;
        overflow-y: scroll;
    }

    .cp-table-card-responsive {
        min-height: 233px;
        max-height: 413px !important;
        overflow-y: scroll;
    }

    .mr-table-card {
        min-height: 323px;
        max-height: auto !important;
        overflow-y: scroll;
    }

    .big-card-table {
        min-height: 360px;
        overflow-y: scroll;
    }

    .full-tab-card-body {
        min-height: 527px;
    }

    .list-card {
        min-height: 612px;
        max-height: 612px;
        overflow-y: scroll;
    }

    .full-tab-page-card-body {
        min-height: 610px;
    }

    .half-screen-card {
        min-height: 286px;
        overflow-y: scroll;
    }

    .big-card-body {
        min-height: 485px;
    }

    .navbar-mobile {
        margin-top: 0px !important;
    }

    .checkboxCol {
        width: 50%;
    }

    /* start here */
    .navbar-top-height {
        padding-top: 0px !important;
        padding-bottom: 0px !important;

        height: 36px !important;

    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .menu-header-bg {
        position: relative;
        left: 0px;
        width: 100% !important;
        top: 0px;

    }

    .toggle-icon-wrapper {
        position: relative;
        left: 0px;
        top: 0px !important;
    }

    .navbar-brand {
        position: relative;
        top: 0px !important;
        left: 40px;
    }

    .avatar-menu-res {
        position: relative;
        top: 0px;
    }

    .navbar-top-button {
        position: relative;
        left: 5px;
        top: 0px;
    }

    .navbar-glass {
        background-color: #2EA7FF !important;
    }

    .form-label {
        font-size: 13px !important;
    }

    /* css end */
}
@media (min-width:1600px) and (min-height:739px) {
    /* .litigation-detail-card-body {
min-height: 480px !important;
max-height: 480px !important;
    } */

    .comman-card .card-body{
        min-height: 570px;
        max-height: 570px;
    }
    .tab-page-tab {

        min-height: 78vh; 
        max-height: 500px !important;
        overflow-y: scroll;
    }
        .list-card {
            min-height: 465px;
            max-height: 465px;
        }
        .footer {
            position: absolute; 
            bottom: 15px !important; 
            z-index: 1000;
          }
          /* .table-responsive{
            display: block;
            overflow-y: scroll;
            min-height: 450px;
            max-height: 450px;
          }
          .full-tab-page-card-body{
            min-height: 600px;
            max-height: 600px;
          } */
    
    
    .next-case-list-card-body table, .client-card-body table{

        /* border: 1px solid rgb(0, 110, 255) !important; */
        /* border: 1px solid rgb(255, 0, 128) !important; */
        overflow-y: scroll !important;
        display: block !important;
        height: 160px !important;
    }
    .client-dashboard-card,
    .next-case-list-card {
            /* height: calc(52vh - 4.3125rem) !important; */
        max-height: 400px !important;
        min-height: 200px !important;
        /* border: 1px solid rgb(21, 114, 78) !important; */
        max-height: 245px !important;
        height: 190px !important;
        /* width: 100%; */
    }
}
@media (min-width:1600px) and (min-height:773px) {
    /* .advocate-dealing-card{
        min-height: 610px;
        max-height:610px;
      } */
    .litigation-detail-card-body {
        min-height: 515px !important;
        max-height: 515px !important;
        /* height: calc(100vh - 5.3125rem); */
    }
    .comman-card .card-body{
        min-height: 600px;
        max-height: 600px;
    }
.tab-page-tab {
    min-height: 79vh; 
    max-height: 500px !important;
    overflow-y: scroll;
}
    .list-card {
        min-height: 500px;
        max-height: 500px;
        
    }
    .footer {
        position: absolute; 
        bottom: 15px !important; 
        z-index: 1000;
      }
      /* ------- */
      
    .next-case-list-card-body table, .client-card-body table{

        overflow-y: scroll !important;
        display: block !important;
        height: 190px !important;
    }
    .client-dashboard-card,
    .next-case-list-card {
            /* height: calc(52vh - 4.3125rem) !important; */
        /* max-height: 400px !important; */
        min-height: 280px !important;
        /* border: 1px solid rgb(114, 21, 67) !important; */
        max-height: 280px !important;
        /* height: 190px !important; */
        /* width: 100%; */
    }

    .client-card-body, .next-case-list-card-body {
        padding: 0px !important;
        height: 200px  !important;
        /* border: 1px solid rgb(21, 108, 114) !important; */  }
}
@media (min-width:1400px) { /* 125% */
    .client-dashboard-card {
        /* border: 1px solid red !important; */
                height: calc(51.2vh - 4.3125rem) !important;
                margin-left: -10px !important;
                width: 101% !important;
                padding: 10px;
            }

            .next-case-list-card {
                           height: calc(51.2vh - 4.3125rem) !important;
                           margin-left: -2px !important;
                           width: 101% !important;
                           padding: 10px;
                       }
                    
            .client-card-body,.next-case-list-card-body {
        padding: 0px !important;
        height: 300px  !important;
               
            }
            .client-card-header {
                margin-left: -10px !important;
                width: 101.5% !important;
            }
            .next-case-card-header{
                margin-left: 0px !important;
                width: 101.5% !important;
            }
            
            .client-card-body tr td{
           padding: 1px !important;
           padding-left: 5px !important;
            }
            .client-card-body .action-footer{
                margin-top: 5px;
                margin-bottom: 3px !important;
            } 
            .action-list-card-body .action-button, .client-card-body .action-button, .next-case-list-card-body .action-button{
                position: absolute;
                left: 420px;

            }
            .action-list-card-body .action-page-parent, .client-card-body .action-page-parent, .next-case-list-card-body .action-page-parent{
                position: relative; 
                display: flex !important;
                justify-content: space-between !important;

            }
                .client-card-body .action-footer, .next-case-list-card-body .action-footer{
                    position: absolute;
                    bottom: 5px;
                    display: flex;
                  
                    /* border: 1px solid red !important; */
                margin-top:5px !important;
                margin-bottom: 3px !important;
            } 
                .action-list-card{
                    /* border: 1px solid red !important;z */
                    /* padding; */
              height: calc(39.5vh - 5.3125rem) !important;
              min-height: 85% !important;
              /* border: 1px solid red !important; */
                }

                .edit-icon{
                   
                    height: 16px !important;
                }
                .next-case-list-card-body{
                    padding:0px !important;
            
                }
            .action-list-card .search-text-column{
                /* margin-top: 4px !important; */
            }
            .client-dashboard-card .search-text-column{
    
                /* margin-top: -10px !important; */
            }
            .next-case-list-card .search-text-column{
                margin-top: -10px !important;
            }
            .next-case-list-card-body tr td {
                padding:1px !important;
                padding-left: 5px !important;
            }

    .custom-textarea {
        width: 535px  !important; 
      }

    .cp-table-card-responsive {
        min-height: 233px;
        min-height: 400px !important;
        overflow-y: scroll;
    }

    .navbar-mobile {
        margin-top: 0px !important;
    }

    .checkboxCol {
        width: 50%;
    }

    /* start here */
    .navbar-top-height {
        padding-top: 0px !important;
        padding-bottom: 0px !important;

        height: 36px !important;
        position: relative;
        top: -3px;
    }

    .navbar-dashboard {
        height: 36px !important;
    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .menu-header-bg {
        position: relative;
        left: 0px;
        width: 100% !important;
        top: 0px;
    }

    .toggle-icon-wrapper {

        position: relative;
        left: 0px;
        top: 0px !important;
    }

    .navbar-brand {
        position: relative;
        top: 0px !important;
        left: 40px;
    }

    .avatar-menu-res {
        position: relative;
        top: 0px;
    }

    .navbar-top-button {
        position: relative;
        left: 5px;
        top: 0px;
    }

    .navbar-glass {
        background-color: #2EA7FF !important;
    }

    .form-label {
        font-size: 13px !important;
    }
    .litigation-detail-card-body {
        height: calc(78vh - 5.3125rem);
    }

    /* css end */
}

@media (min-width:1644px) {
        .table-advance tr td {
            padding: 16px !important;
        }
    .custom-textarea {
        width: 535px  !important; 
      }
    .checkboxCol {
        width: 50%;
    }
    
    .tab-page-tab {
        /* border: 1px solid red !important; */
        min-height: 676px;
        max-height: 900px !important;
        overflow-y: scroll;
    }
    /* start here */

    .navbar-dashboard {
        height: 36px !important;
    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .menu-header-bg {
        position: relative;
        left: 0px;
        width: 100% !important;
        top: 0px;
    }

    .toggle-icon-wrapper {
        position: relative;
        left: 0px;
        top: 0px !important;
    }

    .navbar-brand {
        position: relative;
        top: 0px !important;
        left: 40px;
    }

    .avatar-menu-res {
        position: relative;
        top: 0px;
    }

    .navbar-top-button {
        position: relative;
        left: 5px;
        top: 0px;
    }

    .navbar-glass {
        background-color: #2EA7FF !important;
    }

    .form-label {
        font-size: 13px !important;
    }

    .checkboxText {
        font-size: 13px !important;
    }
    /* css end */
}

@media (min-width:1680px) /* 110 */ {
    .client-dashboard-card, .next-case-list-card {
        /* border: 1px solid rgb(0, 204, 255) !important; */
        /* border: 1px solid red !important; */
        height: calc(50vh - 4.3125rem) !important;
        max-height: 600px !important;
        min-height: 490px !important;
        min-block-size: 100px;
        margin-left: -10px !important;
        width: 101% !important;
        padding: 10px;

    }
    .next-case-list-card{
        margin-left: 0px !important;
    }
                     
    .client-card-body, .next-case-list-card-body {

        /* border: 1px solid red !important; */
        padding: 0px !important;
        /* height: 900px  !important; */
        /* min-height: 990px !important; */
        max-height: 440px !important;

               
            }
            .action-list-card-body .action-button, .client-card-body .action-button, .next-case-list-card-body .action-button{
                position: absolute;
                left: 500px;

            }
            .action-list-card-body .action-page-parent, .client-card-body .action-page-parent, .next-case-list-card-body .action-page-parent{
                position: relative; 
                display: flex !important;
            }
            
            .next-case-list-card-body table, .client-card-body table{

                overflow-y: scroll !important;
                display: block !important;
                height: 350px !important;
            }
          .client-card-body .action-footer, .next-case-list-card-body .action-footer{
             position: absolute;
             bottom: 50px; 
                margin-top: 20px !important;
                margin-bottom: 3px !important;
            } 
        
    .client-card-body {

   padding: 0px !important;
    }
    .client-card-body tr td{
   padding: 7px !important;
    }
    /* .client-card-body .action-footer{
        margin-top: 5px !important;
        margin-bottom: 3px !important;
    }  */
        .edit-icon{
    
            height: 20px !important;
        }
        .next-case-list-card .search-text-column{
            margin-top: -2px !important;
        }
    .action-list-card .search-text-column{
        /* margin-top: 4px !important; */
    }
    .next-case-list-card{
        padding: 5px 10px !important;

        height: calc(57vh - 4.3125rem) !important;
    }
   
    .next-case-list-card-body tr td{
        padding: 6px !important;
    }
    /* .next-case-list-card-body .action-footer{
        margin-top: 4px !important;
    }  */
        .action-list-card{
            height: calc(33.7vh - 4.3125rem) !important;
        }
    .action-padding{
        /* padding: 0px !important; */
    }
    .action-table-advance{
        /* padding: 1px 5px !important; */
    }
    .action-table-advance tr td{
        /* padding: 5px !important; */
    }
      
        .table-advance tr td {
            /* padding: 18px !important; */
        }
    .custom-textarea {
        width: 535px  !important; 
      }
    .container {
        max-width: 1700px !important;
    }

    .checkboxText {
        font-size: 13px !important;
    }
    .footer-credits {
        margin-left: 460px !important;
    }

    .tab-page-tab {
        min-height: 85vh; 
        max-height: 900px !important;
        overflow-y: scroll;
    }

    .list-card {
        min-height: 640px;
        max-height: 640px;
        overflow-y: scroll;
    }

    .tab-page-table-card {
        max-height: 340px;
        overflow-y: scroll;
    }

    .tab-page-button-table-card {
        min-height: 262px;
        max-height: 262px;
        overflow-y: scroll;
    }

    .cp-table-card {
        min-height: 360px;
        max-height: 360px;
        overflow-y: scroll;
    }

    .cp-table-card-responsive {
        min-height: 480px !important;
        max-height: 360px !important;
        overflow-y: scroll;
    }

    .mr-table-card {
        min-height: 373px;
        max-height: auto !important;
        overflow-y: scroll;
    }

    .big-card-table {
        min-height: 400px;
        overflow-y: scroll;
    }

    .max-five-rows {
        max-height: 295px;
        overflow-y: scroll;
    }

    .many-column-table .form-control {
        width: 160px !important;
    }

    .full-tab-card-body {
        min-height: 578px;
    }

    .full-tab-page-card-body {
        min-height: 660px;
    }

    .half-screen-card {
        min-height: 329px;
        overflow-y: scroll;
    }

    .big-card-body {
        min-height: 572px;
    }

    .navbar-mobile {
        margin-top: 0px !important;
    }

    .navbar-vertical .navbar-nav .nav-item .nav-link {
        cursor: pointer;
        font-size: 14px !important;
    }

    /* start here */
    .navbar-top-height {
        height: 36px;

    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .menu-header-bg {
        position: relative;
        left: 0px;
        width: 100% !important;
        top: 0px;
    }

    .toggle-icon-wrapper {
        position: relative;
        left: 0px;
        top: 0px !important;
    }

    .navbar-brand {
        position: relative;
        top: 0px !important;
        left: 40px;
    }

    .avatar-menu-res {
        position: relative;
        top: 0px;
    }

    .navbar-top-button {
        position: relative;
        left: 5px;
        top: 0px;
    }

    .navbar-glass {
        background-color: #2EA7FF !important;
    }
    .litigation-detail-card-body {
        height: calc(80.5vh - 5.3125rem);
    }
    /* css end */
}

@media (min-width:1780px) {
    .next-case-list-card-body table, .client-card-body table{
        /* border: 1px solid red !important; */
        overflow-y: scroll !important;
        display: block !important;
        height: 450px !important;
    }
    /* .container {
        max-width: 1900px !important;
    }
    */
    .client-dashboard-card .search-text-column{

        /* margin-top:0px !important; */
    }

    .client-dashboard-card,
    .next-case-list-card {
        /* border: 1px solid red !important; */
        height: calc(60.5vh - 4.3125rem) !important;
        max-height: 600px;
        width: 100%;
        /* border: 1px solid red !important; */
    }

    .client-card-body tr td,
    .next-case-list-card-body tr td {
        padding: 10px !important;
    }

    .action-list-card {
        height: calc(31vh - 4.3125rem) !important;
    }

    .table-advance tr td {
        padding: 25px !important;
    }

    .litigation-detail-card-body {
        height: calc(82vh - 5.3125rem);
    }
}
@media (min-width:1920px) and (max-height:1073px) {
    .trasaction-list-table {
        display: block;
        min-height: auto;
        max-height: 400px;
        overflow-y:scroll;
        overflow-x: hidden;
 
      }
    .trasaction-list-table tbody td {
     padding-left: 64px !important;
    }

    .litigation-detail-card-body {
        min-height: 810px !important;
        max-height: 810px !important;
        /* height: calc(100vh - 5.3125rem); */
    }
    .tab-page-tab {

        /* min-height: 80vh;  */
        max-height: 500px !important;
        overflow-y: scroll;
    }
        .list-card {
            min-height: 800px;
            max-height: 800px;
        }
        .footer {
            position: relative; 
            bottom: 15px !important; 
            z-index: 1000;
          }
          /* .table-responsive{
            display: block;
            overflow-y: scroll;
            min-height: 450px;
            max-height: 450px;
          } */
          .full-tab-page-card-body{
            min-height: 600px;
            max-height: 600px;
          }
    
}

/* pinkal system 100 % */
@media (min-width:1920px) and (max-height:919px) {
    /* .advocate-dealing-card{
        min-height: 750px;
        max-height:750px;
      } */

      .trasaction-list-table {
        display: block;
        min-height: auto;
        max-height: 400px;
        overflow-y:scroll;
        overflow-x: hidden;
 
      }
    .trasaction-list-table tbody td {
     padding-left: 64px !important;
    }

    .litigation-detail-card-body {
        min-height: 660px !important;
        max-height: 660px !important;
        /* height: calc(100vh - 5.3125rem); */
    }
    .child-comman-card .card-body{
 
        /* border: 1px solid red !important; */
        min-height: 700px;
    }

    .comman-card .card-body{
        min-height: 750px;
        max-height: 750px;
    }
    .tab-page-tab {

        min-height: 82vh; 
        max-height: 500px !important;
        overflow-y: scroll;
        overflow-x: hidden !important;
    }

          /* .table-responsive{
            display: block;
            overflow-y: scroll;
            min-height: 450px;
            max-height: 450px;
          } */
          .full-tab-page-card-body{
            min-height: 600px;
            max-height: 600px;
          }
    
    .client-dashboard-card,
    .next-case-list-card {
        max-height: 400px !important;
        min-height: 420px !important;
        min-block-size: 100px;
        margin-left: -10px !important;
        width: 101% !important;
    }

    .next-case-list-card {
        margin-left: 0px !important;
    }

    .client-card-body,
    .next-case-list-card-body {
        padding: 0px !important;
        max-height: 350px !important;
    }

    .client-card-body .action-button,
    .next-case-list-card-body .action-button {
        position: absolute;
        left: 500px;
    }

    .client-card-body .action-page-parent,
    .next-case-list-card-body .action-page-parent {
        position: relative;
        display: flex !important;
        top: 30px;
    }

    .next-case-list-card-body table,
    .client-card-body table {
        overflow-y: scroll !important;
        display: block !important;
        height: 300px !important;
    }

    .client-card-body .action-footer,
    .next-case-list-card-body .action-footer {
        position: absolute;
        bottom: 50px;
        margin-top: 0px !important;
        margin-bottom: 3px !important;
    }

    .client-card-body {
        padding: 0px !important;
    }

    .client-card-body tr td {
        padding: 5px !important;
    }

    .edit-icon {

        height: 20px !important;
    }

    .next-case-list-card .search-text-column {
        margin-top: -2px !important;
    }

    .next-case-list-card-body tr td {
        padding: 5px !important;
    }

    .action-list-card {
        height: calc(33.7vh - 4.3125rem) !important;
    }

    .list-card {
        min-height: 640px;
        max-height: 640px;
    }

}

/* pinkal system 110 % */
@media (min-width:1735px) and (max-height:835px) {

    .client-dashboard-card,
    .next-case-list-card {
        max-height: 300px !important;
        min-height: 340px !important;
        min-block-size: 100px;
        margin-left: -10px !important;
        width: 101% !important;

    }

    .next-case-list-card {
        margin-left: 0px !important;
    }

    .client-card-body,
    .next-case-list-card-body {
        padding: 0px !important;
        max-height: 300px !important;
    }

    .action-list-card-body .action-page-parent {
        position: relative;
        display: flex !important;
        top: 0px;
    }

    .action-list-card-body .action-button {
        position: absolute;
        left: 500px;
    }

    .client-card-body .action-button,
    .next-case-list-card-body .action-button {
        position: absolute;
        left: 500px;
    }

    .client-card-body .action-page-parent,
    .next-case-list-card-body .action-page-parent {
        position: relative;
        display: flex !important;
        top: 40px;
    }

    .next-case-list-card-body table,
    .client-card-body table {
        overflow-y: scroll !important;
        display: block !important;
        height: 250px !important;
    }

    .client-card-body .action-footer,
    .next-case-list-card-body .action-footer {
        position: absolute;
        bottom: 50px;
        margin-top: 0px !important;
        margin-bottom: 3px !important;
    }

    .client-card-body {
        padding: 0px !important;
    }

    .client-card-body tr td {
        padding: 5px !important;
    }

    .edit-icon {
        height: 20px !important;
    }

    .next-case-list-card .search-text-column {
        margin-top: -2px !important;
    }

    .next-case-list-card-body tr td {
        padding: 5px !important;
    }

    .action-list-card {
        height: calc(33.7vh - 4.3125rem) !important;
    }

    .list-card {
        min-height: 560px;
        max-height: 560px;
    }

    .footer {
        position: absolute;
        bottom: 20px !important;
        z-index: 1000;
    }

}

/* pinkal system 125 % */
@media (min-width:1536px) and (max-height:735px) {
    .tab-page-tab {

        min-height: 77vh; 
        max-height: 500px !important;
        overflow-y: scroll;
    }
        .list-card {
            min-height: 500px;
            max-height: 500px;
        }
        .footer {
            position: absolute; 
            bottom: 15px !important; 
            z-index: 1000;
          }
          .table-responsive{
            display: block;
            overflow-y: scroll;
            min-height: 450px;
            max-height: 450px;
          }
          .full-tab-page-card-body{
            min-height: 600px;
            max-height: 600px;
          }
    

    .client-dashboard-card,
    .next-case-list-card {
        max-height: 240px !important;
        min-height: 240px !important;
        min-block-size: 100px;
        margin-left: -10px !important;
        width: 101% !important;
    }

    .next-case-list-card {
        margin-left: 0px !important;
    }

    .client-card-body,
    .next-case-list-card-body {
        padding: 0px !important;
        max-height: 190px !important;
    }

    .client-card-body .action-button,
    .next-case-list-card-body .action-button {
        position: absolute;
        left: 420px;
    }

    .client-card-body .action-page-parent,
    .next-case-list-card-body .action-page-parent {
        position: relative;
        display: flex !important;
        top: 40px;
    }

    .next-case-list-card-body table,
    .client-card-body table {
        overflow-y: scroll !important;
        display: block !important;
        height: 150px !important;
    }

    .client-card-body .action-footer,
    .next-case-list-card-body .action-footer {
        position: absolute;
        bottom: 50px;
        margin-top: 0px !important;
        margin-bottom: 3px !important;
    }

    .client-card-body {
        padding: 0px !important;
    }

    .client-card-body tr td {
        padding: 5px !important;
    }

    .edit-icon {

        height: 20px !important;
    }

    .next-case-list-card .search-text-column {
        margin-top: -2px !important;
    }

    .next-case-list-card-body tr td {
        padding: 5px !important;
    }

    .action-list-card {

        height: calc(33.7vh - 4.3125rem) !important;
    }

    .list-card {
        min-height: 460px;
        max-height: 460px;
    }

    .footer {
        position: absolute;
        bottom: 20px !important;
        z-index: 1000;
    }

}


@media print {
    .hide-on-print {
        display: none !important;
    }
}


.card-sidebar {
    background-color: #edf2f9;
}