.main-body{
    overflow: hidden !important;
    height: 100% !important;
}

.spinner-border {
    --falcon-spinner-width: 10rem !important;
    --falcon-spinner-height: 10rem !important;
}

.navbar-vertical .navbar-nav .nav-item .nav-link {
    cursor: pointer;
    font-size: 14px;
}

.form-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 75%;
    color: #e63757;
}

.details-form label {
    padding-left: 0px !important;
}

.error-message {
    color: #e63757;
    padding-left: 0px !important;
    font-size: 15px;
}

.table-striped tr:hover {
    cursor: pointer;
}

.row-selected,
.table-striped tbody tr:hover {
    cursor: pointer;
    background-color: rgba(237, 242, 249, 1);
}

.table-striped>tbody>tr:nth-of-type(2n)>* {
    background-color: var(--falcon-table-bg) !important;
}

.navbar {
    padding: 0 0px 0 0 !important;
}

.navbar-top {
    min-height: unset !important;
}

.navbar-top-height {
    height: 36px !important;
}

.navbar-nav {
    --falcon-nav-link-padding-y: 0.3rem !important;
    margin-right: 9px !important;
}

.navbar-glass {
    background-color: #2EA7FF !important;
}

.navbar-menu {
    --falcon-nav-link-padding-y: 0.3rem !important;
    margin-bottom: -5px !important;
    border: 1px solid rgb(243, 240, 240) !important;
}

.toggle-logo {
    padding-bottom: 5px;
}

.navbar-collapse {
    /* max-height: 1260px; */
    height: fit-content !important;
    /* height: 75vh !important; */
    /* overflow: scroll; */

}

.navbar-top {
    margin-left: unset !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: #2EA7FF;
    color: white;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link {
    isolation: isolate;
    border-color: #6c757d47 !important;
    margin-left: 5px;
}

.form-label,
input::placeholder,
.form-control,
.form-select,
.nav-tabs .nav-link,
.error-message {
    font-size: 0.8rem !important;
}

.table-striped {
    font-size: 0.733333rem !important;
}

.table-striped>tbody>tr:nth-of-type(2n)>* {
    color: var(--bs-body-color) !important;
}

#TableFooterPanel .card-body {
    padding: 0 15px 15px 15px;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

#TableSearchPanelCard .card-body {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

#TableFooterPanel,
#ContactDetailsTable {
    margin-bottom: unset !important;
}

.list-card .card-body,
.list-tab-card .card-body {
    padding: 10px 10px 10px 10px;
}

.no-pad .card-body {
    padding: 0px !important;
}

.no-pb,
.no-pb .card-body {
    padding-top: 5px;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}

.no-padding {
    padding: 0px 0px 0px 0px !important;
}

.w-40 {
    width: 40%;
}

.custom-bg-200 {
    background-color: #6c757d47;
}

.table-bordered>:not(caption)>* {
    border-width: 3px 0 !important;
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 3px !important;
}

.tab-page-table>:not(caption)>*>* {
    padding: 0.4rem !important;
}

.tab-page-table td:first-child {
    text-align: center;
    width: 40px;
}

.p3px {
    padding: 3px !important;
}

.p5px {
    padding: 5px !important;
}

.card-header {
    padding: 0.75rem 1rem !important;
}

.passport-sized-photo {
    height: 135px !important;
    width: 125px !important;
}

.logo-photo {
    height: 125px !important;
    width: 125px !important;
}

.space-between-row {
    display: flex;
    justify-content: space-between;
}

.flex-col {
    flex: 1;
}

.row-content-pt5px .card-body {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.no-right-pad {
    padding-right: 0px !important;
}

/* .col-left-pad{
    padding-left: 5px !important;
} */

.vendor-card-body {
    padding: 0 !important;
    margin: 5px !important;
}

.no-left-pad {
    padding-left: 5px !important;
}

.margin-right-11 {
    margin-right: -11px !important;
}

.margin-left-11 {
    margin-left: -11px !important;
    padding-right: 0px !important;

}

.custom-card-scroll {
    max-height: 112px;
    min-height: 111px;
    overflow-y: auto;
}

.custom-card-body-scroll {
    overflow-x: hidden;
}

.card-box-padding {
    padding: 15px 20px 21px 20px !important;
}

.vebdor-card-item {
    padding: 9px 5px 4px 16px !important;
}

.no-padding-mar {
    padding: 0px !important;
    margin: 0px !important;
}

.custom-card-invoice-scroll {
    max-height: 111px;
    min-height: 111px;
    overflow-y: auto;
}

.vebdor-card-invoice-item {

    padding: 10px 5px 4px 16px !important;
}

.farmer-card-item {
    padding: 0px 0px 0px 0px !important;
}

.tm-symbol {
    font-weight: normal;
    font-size: 1.0em;
    vertical-align: super;
}

.menu-header {
    background-color: #2EA7FF !important;
    padding: 7.1px;
    border: 1px solid white;

}

.menu-header .menu-header-link {
    color: white !important;
    text-decoration: none;

}

.noBoxShadow {
    box-shadow: none !important;
}

.actionText {
    color: black !important;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
}

.theme-color {
    color: #2EA7FF !important;
    font-weight: bold;
    margin-top: 5px !important;
    margin-bottom: 15px !important;
    font-size: 20px !important;
}

.theme-color-div {
    padding-bottom: -10px !important;
    margin-bottom: -6px !important;
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
    color: #000 !important;
    background-color: transparent;
    border-color: transparent;
}

#btnSave,
#btnNew,
#btnCancel,
#btnExit {
    z-index: 5000 !important;
}

.subMenuText {
    font-size: 18px !important;
    font-weight: 600 !important;
    background-color: #2EA7FF !important;
    color: white !important;
    padding: 5px;
    padding-left: 20px;
}

.avatar-menu-res {
    margin-bottom: -5px !important;
}

.btn-success.btn:disabled {
    background-color: #27d96b;
}

.dropdown-menu {
    z-index: 5000;
}


.navbar-toggler-humburger-icon:focus,
.navbar-toggler-humburger-icon {
    margin-bottom: 5px !important;
}

.dropdown-menu-card {
    z-index: 5500 !important;
}

.ms-minus-90 {

    padding-left: 0px !important;
    margin-left: -90px !important;
}

.no-right-pad-card {
    border: 1px solid red;
    padding-right: -10px !important;
}

.form-control,
.form-select {
    color: #000 !important;
}

.label-with-colon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.label-text-group {
    display: flex;
    align-items: center;
}

.label-with-colon span:last-child {
    text-align: right;
}

.Falcon-NextDate-Card {
    background: rgb(205, 230, 235) !important;
}

.NextDate-Card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px 5px 0px 0px;
}

.custom-textarea {
    width: 535px !important;
}

.seprator {

    width: 104% !important;
    border-top: 1px solid !important;
    margin-left: -16px !important;
}
.label-with-action-colun{
    display: flex;
    justify-content: flex-end !important;
    align-items: flex-end !important;
}

.label-colon{

    display: flex !important;
    justify-content: space-between !important;
    padding: 0px !important;

}

.action-text-input{
    padding-left: 12px !important;
}

.action-list-card-header h5{
    font-size: 1rem !important ;
    font-weight: 500 !important;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-weight: 500;
    line-height: 1.2;
    color: #344050 !important;
    margin-left: -16px  !important;

}

/* FullCalendar General Styles */
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: white !important;
    background-color: #2ea7ff !important;
    border-color: var(--bs-nav-tabs-link-active-border-color);
}

.fc {
    font-family: Arial, sans-serif;
    font-size: 14px;
    width: 100%; 
    max-width: 1200px; 
  }
  
  .fc-day:hover {
    background-color: #f5f5f5; 
    cursor: pointer;
  }

   .fc .fc-h-event {
    border: 1px solid red !important;
    color: white !important;
    
}

.fc.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc.fc-direction-rtl .fc-daygrid-event.fc-event-end, .fc.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    width: 5px !important;
padding: 10px !important;
border-radius: 50% !important;
text-align: center !important;
}

.fc-daygrid-event-harness {
    margin-top: -31px  !important;
    margin-left: -9px !important;
    text-align: center !important;
    align-items: center !important;
}

.fc .fc-daygrid-event {
    font-size: 1px !important;
    border: 1px solid red !important;
    background-color: var(--falcon-primary) !important;
    width: 30px !important;
    padding: -5px !important;
    z-index: 0 !important;
    text-align: center !important;
}
.fc .fc-daygrid-day-number {
    text-align: center !important;
    align-items: center !important;
    text-decoration: none !important;
    line-height: 1.875rem;
    padding: 0 !important;
    font-size: .8333333333rem;
    transition: all .2s ease-in-out;
    background-color: none !important;
    background: none !important;
}

.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:hover {
    background-color: #05050598 !important;
    background: #000 !important;
    color: #f7f1f1 !important;
    padding: 5px !important; 
}
.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
    background-color: #23292d !important;
    color: #fff;
    padding: 0px;
}

.fc-daygrid-day .fc-event {
    margin-top: 1px !important  ;
    margin-left: 1px !important;
    margin-bottom: 5px !important;
    text-align: center;
    line-height: 30px; 
    width: 10px !important; 
    height: 10px !important;
    margin: 0 auto; 
  }
  
  .fc-daygrid-day .fc-event .fc-event-title {
    color: #fff; 
    font-size: 14px; 
    font-weight: bold;
  }
  
.has-event {
    color: white !important; 
    border: 2px solid blue; 
  }

  .tooltip-right {
    top: -5px;
    left: 105%;
  }
  
  .tooltip-left {
    top: -5px;
    right: 105%;
  }
  
  .tooltip-top {
    bottom: 105%;
    left: 0;
  }
  
  .tooltip-bottom {
    top: 105%;
    left: 0;
  }

  .calenderCard {
    padding-left: 0px !important;
      padding: 0px !important;
      margin: 0px !important;
  }

.fc-tooltip {
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 999;
  }
  
  .fc-tooltip .tooltip-arrow {
    position: absolute;
    top: 50%;
    left: -10px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 10px solid #333;
    transform: translateY(-50%);
  }
  
.fc-day-today{
    border: 2px solid rgba(var(--falcon-primary-rgb), .5) !important;
}
.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-frame {
    border: none !important;
}

.fc-daygrid-day-number {
    font-weight: bold;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
    font-size: 14px;
    margin-top: -5px;
    margin-left: 5px !important;
    font-weight: bold;
    padding: 0px !important ;
  }

  .windows.chrome .fc-scroller {
    overflow: hidden !important;
}

.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:hover {
    background-color: #1862c6 !important;
    padding: 0px !important;
}

.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
    background-color: #1862c6 !important;
    padding: 0px !important;
    width: 20px !important;
    height: 20px !important;
    margin-top: 0px !important;
    margin-right: -1px !important;
}

.fc .fc-scroller-harness {
    overflow: hidden !important;
}

.windows.chrome .fc-scroller {
    overflow: hidden !important;
}

.footer-mb{
    margin-top: -30px;
}

.footer-mb p {
    font-size: 11px !important;
}

.btn-falcon-default:hover , .button-arrow:hover{
    background-color: #2EA7FF !important;

    .button-arrow{
        color: white !important;
    }
}

.custom-table tbody tr:nth-child(odd) {
    background-color: #ffffff;
}

.custom-table tbody tr:nth-child(even) {
    background-color: #f9fafd;
}

.remove-card-bottom-p{
    padding-top: 7px !important;
    padding-bottom: 4px !important;
}

.fc .fc-daygrid-day-frame {
    padding: 0px !important;
    margin-top: 5px !important;
    margin-left: 5px !important;
    margin-bottom: 20px !important;
    text-align: center !important;
    width: 0px !important;
    height: 0px !important;
}

.fc-daygrid-day {
  width: 14.28%; 
}

.fc-daygrid-day {
    width: 5px !important; 
    height: 5px !important; 
  }

.table-advance  tr td{
    padding: 20px !important;
}

.table-title {
    color: red !important
}

.modelClientList{
    margin-left: -6px !important;
    width: 510px;
}
.table-advance td{
    color: #000 !important;
}

.client-list-header{
    color: #344050 !important;
}

.client-list-popup-card-heading
{
    margin-top: -6px !important;
}
.fc-day-today.has-event {
    .fc-daygrid-event {
        position: relative !important;
        top: 5px !important;
        left: 2px;
    }

}

.card-calendar{
    border: 1px solid red !important;
    height: 270px !important;
    margin: 2px !important;
}
  
.ml-0{
    margin-left: 0px !important;
    padding-left: 0px !important;
}

.card-bottom-padding{
    margin-bottom:2px !important
}

.client-card{
    margin-left: -14px !important;
    background-color: rgba(var(--falcon-light-rgb), var(--falcon-bg-opacity)) !important;
}
.client-card-header{
    margin-left: -12px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
}

.remove-border-client{
    border-radius: 5px !important;
}
.client-card-b{

    margin-left: 13px !important;
    width: 49% !important;
}
.card-dashbord-bg{
    background: #28b658 !important;
    color: #fff !important;
}

.case-schedule-card-dash{
    margin-right: -50px !important;
}

.dasbhoard-row{
    max-height: calc(100vh - 4.3125rem) !important    ;
}

.dasbhoard-row {
    display: flex;
    flex-wrap: wrap;
  }

   .footer {
    position: absolute; 
    bottom: 25px !important; 
    z-index: 1000;
  }

  .action-padding{
    padding: 10px !important;
  }

.action-table-advance .search-text-column {
    margin-top: 7px !important;
}

.action-table-advance .action-footer{
    margin-top: 5PX !important;
    margin-bottom: 5px !important;

}

.truncate-text {
    display: inline-block;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  

.client-name-column{
    width: 210px !important;
}

.email-id-column{
    width: 275px !important;
}

.no-of-case-column{
    /* width: 140px !important; */
    text-align: center !important;
    padding-right: 1px !important;
    padding-left: 1px !important;
}

.client-detail-column{
    width: 200px !important;
}

.next-case-list-card-body tr th, .client-card-body tr th,.action-table-advance tr th{ 
padding: 3px !important;
padding-left: 5px !important;
}

.next-case-list-card-body tr td,
.client-card-body tr td,
.action-table-advance tr td {
    padding: 2px !important;
    padding-left: 5px !important;
}

    .td-truncate-text {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 150px !important;
    }


.action-table-advance table{
    overflow-y: scroll !important;
    display: block;
    height: 170px;

}

.action-table-advance table thead {
    overflow-y: hidden !important;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1; 
    text-wrap: nowrap;
  }

.action-list-card .search-text-column{
    margin-top: -6px !important;
}
.action-table-advance .action-footer{
    margin-top: 10px !important;
    margin-bottom:-10px !important;
} 
.action-list-card .search-text-column{
    margin-top: -6px !important;
}
.next-case-list-card .search-text-column{
    margin-top: -2px !important;
}
.client-dashboard-card .search-text-column{
    margin-top: -7px !important;
}

.email-id-column {
    width: 200px !important;  
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.action-card-caseno{
    width:100px !important;
}
.action-card-courtname{
    width: 180px !important;
}

.action-table-advance table::-webkit-scrollbar {
    width: 8px;
    height: 8px; 
    background-color: transparent !important;
}

.action-table-advance table::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
    background-color: transparent !important;
}

.action-table-advance table::-webkit-scrollbar-thumb {
    background-color:var(--falcon-scrollbar-bg) !important;
    border-radius: 3px;
    border: 2px solid #ffffff; 
}

.action-table-advance table:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

.action-table-advance table::-webkit-scrollbar-thumb {
    visibility: visible;
}

.next-date-column{
    width: 140px !important;
}
.email-clientcard-column{
    width: 250px !important;
}
.noofcase-clientcard-column{
    width: 130px !important;
}

.status-casenextCard-column{
    width: 130px !important;
}
/* cleint and case card */


.next-case-list-card-body table, .client-card-body table{
    overflow-y: scroll !important;
    display: block !important;
    height: 400px !important;
}

.next-case-list-card-body table thead , .client-card-body table thead{
    overflow-y: hidden !important;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1; 
    text-wrap: nowrap;
  }

   .next-case-list-card-body table::-webkit-scrollbar {
    width: 8px;
    height: 8px; 
    background-color: transparent !important;

}


.next-case-list-card-body table::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
    background-color: transparent !important;
}

.next-case-list-card-body table::-webkit-scrollbar-thumb {
    background-color:var(--falcon-scrollbar-bg) !important;
    border-radius: 3px;
    border: 2px solid #ffffff; 
}


.next-case-list-card-body table:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

.client-card-bodynext-case-list-card-body table::-webkit-scrollbar-thumb {
    visibility: visible;
}
.client-card-body table::-webkit-scrollbar-thumb {
    background-color:var(--falcon-scrollbar-bg) !important;
    border-radius: 3px;
    border: 2px solid #ffffff; 
}
.client-card-body table::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
    background-color: transparent !important;
}

.client-card-body table::-webkit-scrollbar {
    width: 8px;
    height: 8px; 
    background-color: transparent !important;
}

.action-head {
    display: table !important;
    width: 100%;
    overflow-y: hidden !important;
}
.dashboard-bolow-card{
    margin-top: 10px !important;
    margin-bottom: 10px;
}
.next-case-card-header{
    text-wrap: nowrap;
    }

.client-list-pop-up {
    font-size: 0.733333rem !important;
}
    
.row-selected,
.client-list-pop-up tbody tr:hover {
    cursor: pointer;
    background-color: rgba(237, 242, 249, 1);
}
.text-amber {
    color: #ffbf00 !important;
}
    
.text-red {
    color: red !important;
}
    
.text-blue {
    color: blue !important;
}
    
.my-modal {
    --falcon-modal-width:29% !important;
    /* --falcon-modal-height: 80vh !important; */
}    

.table-container {
    max-height: 210px;
    overflow-y: auto;
    border: 1px solid #ddd;
}

.table-container::-webkit-scrollbar {
    width: 4px;
}

.table-container::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
}

.table-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}